<template>
  <div>
    <vm-table ref="vmTable" :filter.sync="search" url="debugParamManages">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(0)">
          {{ $t("common.add") }}
        </el-button>
      </template>
      <table-column prop="no" label="参数号" :width="100" align="center"></table-column>
      <table-column prop="name" label="参数名"></table-column>
      <table-column prop="typeNames" :width="400" label="所属模块"></table-column>
      <table-column prop="range" label="范围" :width="200"></table-column>
      <table-column prop="decimalPlace" label="小数位" :width="100" align="center"></table-column>
      <table-column prop="defaultValue" label="默认值" align="center" :width="100"></table-column>
      <table-column prop="unit" label="单位" align="center" :width="100"></table-column>
      <table-column :label="$t('common.operation')" :width="100" align="center" :tooltip="false">
        <template #default="scope">
          <el-button type="text" @click="$refs.editPage.open(scope.row.id)">{{ $t("common.edit") }}</el-button>
          <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">{{ $t("common.delete")
            }}</el-button>
        </template>
      </table-column>
    </vm-table>
    <params-manage-edit ref="editPage" @save-success="getList(-1)"></params-manage-edit>
  </div>
</template>
<script>
import ParamsManageEdit from "./ParamsManageEdit";

export default {
  components: { ParamsManageEdit },
  data() {
    return {
      search: {
        filter: "",
      },
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },

    handleDelete(row) {
      this.$confirm(this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$http.delete("debugParamManages/" + row.id).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
  },
};
</script>